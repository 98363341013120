import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";

const ImageGrid = ({ children, path, name, ratio }) => {
    return (
        <Grid container style={{height: '100%', justifyContent: 'center', paddingBottom: children? 0:'2%'}}>
            {name.map((n, i) => (
                <Grid item xs={ratio[i]} key={path+n}>
                    <Box
                        component="img"
                        className='blogGridImage'
                        src={`${process.env.PUBLIC_URL}/blog/${path}/${n}.jpg`}
                    />
                </Grid>
            ))}
            <Typography variant="caption" className='blogCaption'>
                {children}
            </Typography>
        </Grid>
    );
};

export default ImageGrid;
