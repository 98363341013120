import {Typography, useMediaQuery} from '@mui/material';
import Box from "@mui/material/Box";
import React from "react";

import TextBody from "./modules/textBody";
import Image from "./modules/image";
import PreviewCard from "./modules/previewCard";


const Hi = ({ isPreview }) => {
    const isMobile = useMediaQuery('(max-width:768px)');
    const folderPath="hi";

    return(
        <Box>
            {isPreview &&
                <PreviewCard path={folderPath}
                             title={"Hi There!"}
                             date={"Sep 25, 2024"}
                             isMobile={isMobile}>
                    How it all started
                </PreviewCard>
            }
            {!isPreview &&
            <Box className="content" sx={{width: isMobile ? '90%' : '80%', margin: '0 auto'}}>
                <Typography variant={isMobile ? "h4" : "h3"} className='blogTitle'>Hi There!</Typography>
                <Typography color="textSecondary" className='blogDate' gutterBottom>
                    September 20, 2024
                </Typography>
                <Box className='bolgContent'>
                    <TextBody>
                        It all started with hanging around in Urban Outfitter before my trip to Alaska back in March
                        2023. At checkout, I came across the Kodak FunSaver disposable camera. I had been using a
                        Polaroid, but its limitations were starting to frustrate me. After a few trips with
                        disappointing photos, I was ready to try something new. So when I saw this simple, cheap film
                        camera, I thought, "This has to be better than a Polaroid—why not?" I grabbed two.
                    </TextBody>
                    <TextBody>
                        As expected, the photos turned out way better than the Polaroids, and I was thrilled. I love
                        that you have to wait and develop the film before seeing the results. All the effort built up
                        into pure excitement when I finally got to see the photos. Here are a few of my favorites from
                        back then.
                    </TextBody>
                    <Image path={folderPath} name={1}>
                        Ice-fishing in Fairbanks
                    </Image>
                    <Image path={folderPath} name={2}>
                        Sunset on the ice-fishing lake
                    </Image>
                    <TextBody>
                        I have to mention that since the first time I shot with film cameras, I sent them to The
                        Darkroom to develop. They are very reliable and professional, and their updates of "We received
                        your order", "Your photos are being uploaded" and "Your photos are on the way" are some of the
                        best emails I can expect. I've trusted them with my films ever since.
                    </TextBody>
                    <Image path={folderPath} name={3}>
                        Sunset in downtown Fairbanks
                    </Image>
                    <Image path={folderPath} name={4}>
                        On the way of cross-country skiing
                    </Image>
                    <TextBody>
                        I do see they are not that good and have some serious problems now, but switching from
                        Polaroids, these results are already huge upgrades and absolutely encourage me to keep going.
                    </TextBody>
                    <Image path={folderPath} name={5}>
                        Took with Canon Sure Shot in Lassen Volcanic National Park
                    </Image>
                    <TextBody>
                        I realized film cameras could work. After months of shooting with point-and-shoots, I decided it
                        was time to make some upgrades. I’ve got to give a shoutout to Houston for having such a great
                        local camera store—Houston Camera Exchange. Joe was there for me. He picked out a fully manual
                        Nikon FE for me, along with a 35-105mm lens. He walked me through everything, explaining how
                        every button and number works. He even took the time to show me how to properly switch lenses to
                        avoid dust, then watched me practice. Before leaving, I took a picture of him—the man who taught
                        me all. He said it's his responsibility to pass down the knowledge as much as possible before
                        he's gone.
                    </TextBody>
                    <Image path={folderPath} name={6}>
                        Joe, the hero
                    </Image>
                    <TextBody>
                        And that’s where my adventures really began.
                    </TextBody>
                    <TextBody>
                        I've had that Polaroid for 6 years now and thanks to it, I've captured so many wonderful moments
                        with friends. I still keep it with me on every trip, even though I barely use it. Just in case I
                        meet someone special, I'd love to snap an irreplaceable picture with them and hold that memory
                        in my pocket.
                    </TextBody>
                    <TextBody>
                        The idea for this website came from developing the films I shot on my recent trip to Oregon. The
                        photos turned out so beautifully, and after holding cameras for a while now, I finally have
                        something I’m proud of. I believe that pictures themselves don’t hold meanings: it’s the stories
                        behind them that matter. The person who captures the moment gives meaning to the photos.
                        Sometimes, I want to share those behind-the-scenes stories. I’ll also share some of my trip
                        plans because I don’t always aim for touristy places. I’d be excited if my stories could inspire
                        you even a little.
                    </TextBody>
                </Box>
            </Box>
            }
        </Box>
    )
};

export default Hi;
