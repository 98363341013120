import Box from "@mui/material/Box";
import React from "react";


const Music = ({ src, title }) => {
    return (
        <Box className='blogText'>
            <iframe style={{borderRadius: "12px"}}
                    src={src}
                    width="100%" height="152" frameBorder="0" allowFullScreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy" className='musicBlock' title={title}></iframe>
        </Box>
    );
};

export default Music;
