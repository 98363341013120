import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";


const TextBody = ({ children }) => {
    return (
        <Box className="blogText">
            <Typography variant="body1" className="blogParagraph">
                {children}
            </Typography>
        </Box>
    );
};

export default TextBody;
