import {scrollIntoView} from "seamless-scroll-polyfill";

export const scrollToSection = (ref) => {
    if (ref.current) {
        scrollIntoView(ref.current, {
            behavior: "smooth",
            block: "start",
        },{
            duration: 250
        });
    }
};
