import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";


const TextSubtitle = React.forwardRef(({ children }, ref) => {
    return (
        <Box className="blogText">
            <Typography ref={ref} variant="h6" className='blogParagraph'>
                {children}
            </Typography>
        </Box>
    );
});

export default TextSubtitle;
