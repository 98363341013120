import { Typography, useMediaQuery} from '@mui/material';
import Box from "@mui/material/Box";
import React from "react";
import Drawer from "@mui/material/Drawer";

import TextBody from "./modules/textBody";
import TextTitle from "./modules/textTitle";
import Image from "./modules/image";
import Music from "./modules/music";
import ImageV from "./modules/imageV";
import ImageSmall from "./modules/imageSmall";
import ImageGrid from "./modules/imageGrid";
import PreviewCard from "./modules/previewCard";
import MenuButton from "./modules/menuButton";
import MenuDrawer from "./modules/menuDrawer";

const Utah = ({ isPreview }) => {
    const [open, setOpen] = React.useState(false);

    const openDrawer = (value) => () => {
        setOpen(value);
    };

    const isMobile = useMediaQuery('(max-width:768px)');

    let titles=[
        'Day 1 Into Utah',
        'Day 2 Going Wild',
        'Day 3 Caves and Bryce Canyon NP',
        'Day 4 Capitol Reef NP in Snow',
        'Day 5 Arches National Park',
        'Day 6 Canyonlands National Park',
        'Day 7 Monument Valley and Shiprock',
        'Day 8 Petrified Forest NP',
        'Day 9 Home',
    ];
    const refs = React.useRef(Array.from({ length: titles.length }, () => React.createRef()));

    const folderPath="utah";

    return (
        <Box>
            {isPreview &&
                <PreviewCard path={folderPath}
                             title={"Thanksgiving in Utah"}
                             date={"December 4, 2024"}
                             isMobile={isMobile}>
                    The road is wild but I am too
                </PreviewCard>
            }
            {!isPreview &&
                <Box className="content" sx={{width: '80%', margin: '0 auto'}}>
                    <Typography variant={isMobile ? "h4" : "h3"} className='blogTitle'>Thanksgiving in Utah</Typography>
                    <Typography color="textSecondary" className='blogDate' gutterBottom>
                        December 4, 2024
                    </Typography>
                    <Box className='bolgContent'>
                        <Music src={"https://open.spotify.com/embed/track/5mHdCZtVyb4DcJw8799hZp?utm_source=generator"}
                               title={"Escapism. by RAYE"}/>
                        <TextBody>
                            I had no idea where to go for this Thanksgiving at first. I went through my map, trying
                            to find the gaps among the places I had been to. I found a huge gap in Utah. The last
                            time I was in Utah, my main goal was to see Antelope Canyon. Now I know about so many
                            other beautiful spots, so this is it. Flying to Phoenix since I’ve never been to Phoenix
                            before.
                        </TextBody>
                        <TextBody>
                            The vibe for this road trip was RAYE and Tove Lo. I listened to tons of their songs
                            before, during, and after the trip. I can't remember how many times I played 'Escapism.'
                            by RAYE. My road trip vibe for the past few months had always been Maneskin. I can never
                            fall asleep driving while listening to their music. I also love that line from 'THE
                            DRIVER': If there’s nowhere to go when you wanna go wild, I wanna be the driver.
                        </TextBody>
                        <TextTitle ref={refs.current[0]}>
                            {titles[0]}
                        </TextTitle>
                        <Image path={folderPath} name={"m1"}>
                            First day
                        </Image>
                        <TextBody>
                            Leaving home on a Saturday morning for an airport I had never been to. The road was
                            surprisingly quiet and peaceful, the neighborhood was surprisingly quiet and peaceful,
                            and the airport was surprisingly quiet and peaceful.
                        </TextBody>
                        <ImageV path={folderPath} name={1}>
                            Little star in the airport
                        </ImageV>
                        <TextBody>
                            I did my research before going. I knew I was aiming for lots of off-road attractions, so
                            I rented a standard SUV, hoping it would at least be AWD. When I got to the counter, the
                            lady told me the standard SUVs weren’t AWD or 4WD. She said I could upgrade to a 4x4,
                            which would be a Jeep Wrangler, and it was the cheapest option. I hesitated. It seemed
                            like too much. I did want to drive this cool car, but I had also heard rumors about how
                            difficult it is to handle. That lady assured me, 'It’s super easy to drive, SUPER EASY.'
                            Now with the opportunity right in front of me, there’s no way I could say no. Destiny
                            wanted me to drive a badass Wrangler.
                        </TextBody>
                        <ImageV path={folderPath} name={2}>
                            BADASS
                        </ImageV>
                        <TextBody>
                            I made a quick stop in Sedona to catch the sunset. By the time I was on my way to the
                            Chapel of the Holy Cross, it was already golden hour.
                        </TextBody>
                        <Image path={folderPath} name={3}>
                            Just a random pullout point in Sedona
                        </Image>
                        <TextBody>
                            I missed the chapel's opening hours. Even though the gate was open, I didn’t go inside
                            because of the "No Trespassing" sign.
                        </TextBody>
                        <ImageV path={folderPath} name={4}>
                            A quick picture in front of someone's house
                        </ImageV>
                        <TextBody>
                            Time for dinner. Big Water is a small town with no dining options, but I happen to have
                            a favorite restaurant in Page—El Tapatio. I was here two years ago, and their pig
                            stomach tacos are everything.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[5, 6]} ratio={[6, 6]}>
                            At El Tapatio
                        </ImageGrid>
                        <TextBody>
                            No luck with the stars for that night though. It was pretty cloudy.
                        </TextBody>
                        <TextTitle ref={refs.current[1]}>
                            {titles[1]}
                        </TextTitle>
                        <Image path={folderPath} name={"m2"}>
                            For White Pocket(C) and White Hoodoos(E)
                        </Image>
                        <TextBody>
                            I had plans for other places, but time wouldn’t allow it. Just these two spots alone
                            required at least 7 hours of driving, and both of them involve a lot of rough off-road
                            driving.
                        </TextBody>
                        <TextBody>
                            I joined the lottery for The Wave but didn’t have any luck. If I had gotten the permit,
                            my plan for the day would simply be The Wave. But looking back, White Pocket was truly
                            something special and hard to let go of. Maybe The Wave is even more charming. There’s
                            no way to find out this time.
                        </TextBody>
                        <TextBody>
                            I found a very convincing reason to leave early for White Pocket: the earlier you
                            arrive, the better your chances of getting roadside assistance if you get stuck. I read
                            about people waiting 6 hours for rescue and ending up with a $6,000 bill after getting
                            trapped in the sand.
                        </TextBody>
                        <ImageV path={folderPath} name={7}>
                            A gate you need to go through
                        </ImageV>
                        <TextBody>
                            It’s actually difficult to go the wrong way if you stick to the official guides. The
                            first half of the off-road drive was gravel and dirt. I drove carefully and slowly until
                            another Wrangler flew past me. That gave me the confidence to pick up the pace. The
                            second half was mostly sand, with some rocks sticking out that made the ride pretty
                            bumpy. I don’t think it’s easy to get stuck as long as you stay on the path, follow the
                            tire marks left by others, and avoid going full speed on the sand—because it’s so easy
                            to drift.
                        </TextBody>
                        <TextBody>
                            The road was narrow, so we often had to give way to each other. There weren’t many
                            people I came across while driving, but everyone I did meet was so nice. We showed
                            respect, waved, and said hi to each other. It was truly lovely.
                        </TextBody>
                        <TextBody>
                            I think the drive was pretty fun. I felt the charm of the Wrangler and the thrill of
                            going off-road with bare concern.
                        </TextBody>
                        <ImageSmall path={folderPath} name={9}>
                            You are free to explore anywhere at White Pocket
                        </ImageSmall>
                        <TextBody>
                            You need to walk a short distance to reach the White Pocket area. The fact that you can
                            step anywhere is amazing. I had so much fun climbing up and down taking pictures.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[10, 11, 12]} ratio={[5, 3, 4]}></ImageGrid>
                        <ImageGrid path={folderPath} name={[13, 14, 15]} ratio={[3, 3, 6]}></ImageGrid>
                        <Image path={folderPath} name={16}>
                            THE White Pocket
                        </Image>
                        <TextBody>
                            I hated to leave but time was ticking. I wanted to leave White Hoodoos before dark. The
                            drive back felt much more confident than the drive in. Went back to Page for gas and a
                            quick bite, then I hit the road again.
                        </TextBody>
                        <TextBody>
                            I had visited Lake Powell before, so I didn’t include it in my plans this time. Still,
                            it’s stunning and definitely worth a visit.
                        </TextBody>
                        <Image path={folderPath} name={17}>
                            Stopped by a pullout point when leaving Page
                        </Image>
                        <TextBody>
                            Before I came, I heard everyone talking about how difficult the drive to White Pocket
                            is. Honestly, I don’t think that’s true. As long as you have a high-clearance 4x4
                            vehicle, drive cautiously, you’ll be fine. But the drive to White Hoodoos is a different
                            story. The off-road section wasn’t as long, but it was much bumpier, going up and down
                            hills with sharp turns.
                        </TextBody>
                        <TextBody>
                            When I got close to the unmarked trailhead for White Hoodoos, I saw another car parked
                            there, and my GPS told me I had arrived. Thinking I was in the right spot, I parked next
                            to that car. The way to White Hoodoos was to follow the dry riverbed. I noticed a small
                            opening in the fence surrounding the area and saw lots of footprints, so I went for it.
                        </TextBody>
                        <Image path={folderPath} name={18}>
                            In fact the wrong spot to go
                        </Image>
                        <TextBody>
                            I was so wrong. The footprints led me to a cliff overlooking the dry riverbed. That’s
                            when I saw people walking on the riverbed below and finally understood what "follow the
                            dry riverbed" actually meant. I started looking for a way to climb down but quickly
                            realized I couldn’t, unless I jumped off the cliff and surely broke my neck. I wandered
                            around and ended up on a small hill. From there, I saw two other cars parked not far
                            from mine, so I rushed back, hopped in my car, and drove to that spot.
                        </TextBody>
                        <TextBody>
                            Now I saw the correct way to go. The opening in the fence was so small that it could
                            only fit one person. There was no chance you could see it from afar.
                        </TextBody>
                        <TextBody>
                            After getting lost and then finding the right path, I had wasted some precious time. My
                            original plan would have worked perfectly if nothing had gone wrong. I was supposed to
                            be out of the off-road area before dark, but now I wasn’t so sure. I had to hurry up.
                        </TextBody>
                        <TextBody>
                            The hike to the hoodoos was said to be about a mile. When I was barely halfway there, I
                            met a guy heading back who told me, "You’re almost there." I naively thought: almost
                            there.
                        </TextBody>
                        <TextBody>
                            I started questioning myself when I hit 1 mile but still saw nothing. Then, I noticed
                            the tire marks continued (someone had driven in before, you shouldn't do that!!!). I
                            followed them and finally reached the hoodoos. They weren’t as stunning as I expected.
                            I’m not sure if it was the time of day—there was no direct sunlight—or something else,
                            but the scene didn’t match the effort I put in at all. Also, if you want a close-up
                            photo, you’ll need to take your time climbing those rocks.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[19, 20]} ratio={[6, 6]}>
                            White Hoodoos
                        </ImageGrid>
                        <TextBody>
                            The sunset started before I hiked back. I could see the golden tops on the other side of
                            the canyon and pink clouds, but since I was at a lower point, my view was obstructed.
                            The ground was covered in pebbles and mud, making it really difficult to walk fast. I
                            managed to get back and caught the last bit of the sunset.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[21, 22]} ratio={[6, 6]}>
                            Glimpse of sunset
                        </ImageGrid>
                        <TextBody>
                            As I mentioned, the off-road drive to White Hoodoos isn’t easy, let alone when it’s
                            dark. I drove like crazy, trying my best to get out of there before it was completely
                            dark. When I finally made it out, I pulled over to the side of the road and rested for
                            a while. I couldn’t believe what I had just gone through.
                        </TextBody>
                        <TextBody>
                            Drove to Kanab for dinner and to stay the night. Nicholas and Celeste were my Airbnb
                            hosts. I didn’t expect much from their place, considering it was cheap and in a small
                            town. But their place felt like a castle. I was totally blown away.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[23, 24, 25]} ratio={[4, 4, 4]}>
                            Inside their house
                        </ImageGrid>
                        <TextTitle ref={refs.current[2]}>
                            {titles[2]}
                        </TextTitle>
                        <Image path={folderPath} name={"m3"}>
                            New plan for the day
                        </Image>
                        <TextBody>
                            My original plan was to hike to Cosmic Ashtray. But since I was so tired from the
                            previous day’s drive, I made a spur-of-the-moment decision to skip the hike and aim on
                            sightseeing spots that were accessible by car. Nicholas gave me plenty of great advice,
                            I marked all of them on my map and here we go.
                        </TextBody>
                        <TextBody>
                            Before I left, I had something important to do—meet the other family members: two
                            donkeys and a horse.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[26, 27, 28]} ratio={[5, 3, 4]}>
                            Other cute family members
                        </ImageGrid>
                        <TextBody>
                            They were very welcoming and curious about me, or maybe they were just hungry. I’m not
                            sure.
                        </TextBody>
                        <TextBody>
                            First stop: Sand Cave. Nicholas told me it was an easy short hike. Everything felt
                            peaceful and fun until I realized I needed to climb a steep slope to reach the caves.
                        </TextBody>
                        <ImageV path={folderPath} name={29}>
                            Really can't see how steep it is from the picture
                        </ImageV>
                        <TextBody>
                            This was the place where the lie of "this is an easy hike" in Utah began. It’s such a
                            lie that sometimes when I’m climbing up and down the rocks, I laugh at myself. So this
                            is easy, huh? This trail where I could easily slip fall and die is easy, huh?
                        </TextBody>
                        <TextBody>
                            The view was totally worth it if you don’t think about how you’re going to get down from
                            that place.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[30, 31]} ratio={[6, 6]}>
                            Sand Cave
                        </ImageGrid>
                        <TextBody>
                            When I was standing on the edge of the cave, I looked down and saw someone hiking toward
                            the caves. A man down there said she could fall and die. Yes, I’m deeply aware of that.
                            Thanks for the heads up.
                        </TextBody>
                        <ImageV path={folderPath} name={32}>
                            Someone down there was reminding me
                        </ImageV>
                        <TextBody>
                            Next stop: The Belly of the Dragon. Not much to say, it’s a tunnel near the parking lot.
                            It’s beautiful.
                        </TextBody>
                        <ImageV path={folderPath} name={33}>
                            The tunnel you see with bare eyes is more golden
                        </ImageV>
                        <TextBody>
                            The weather worsened as I drove north. It started snowing. I decided to make a stop at
                            Bryce Canyon National Park since there weren’t many options to choose from in that
                            weather. I had been here before, and I still remembered everything when I came back. I’m
                            not sure if this sign was here last time, but I love this pun.
                        </TextBody>
                        <Image path={folderPath} name={34}>
                            I love hoodoos
                        </Image>
                        <TextBody>
                            It’s beautiful when the weather is nice at Bryce Canyon, but it wasn’t so bad when it
                            was snowing and foggy. I was so excited when I saw the snow. I’m always excited for
                            snow. I really love when people use "crispy" to describe the weather, such an accurate
                            word. The air was crispy, exactly how I like it.
                        </TextBody>
                        <Image path={folderPath} name={35}>
                            Natural Bridge in the snow
                        </Image>
                        <TextBody>
                            I wanted to go for the Mossy Cave Trail, but I was not in the mood because of the
                            weather.
                        </TextBody>
                        <TextBody>
                            I made a quick stop at Escalante Petrified Forest State Park because I thought it might
                            be similar to Petrified Forest National Park. It is not. There was no one at the park
                            when I arrived. I think it’s more of a spot for picnics and water activities. The lake
                            was half frozen, the weather was gloomy, and there was no point in waiting for the
                            sunset.
                        </TextBody>
                        <TextBody>
                            Now I was ready for dinner. I was staying in Loa for the night, which is a small town.
                            It was a Tuesday, so I figured I’d have more choices in Torrey. I picked a place from
                            the map and started driving along Highway 12. This is a scenic drive, but the view after
                            Escalante wasn’t very surprising, and it was getting dark. The drive after Escalante
                            became more difficult, with more turns as I drove through the mountains. Especially when
                            I was climbing up, the altitude gain caused snow to accumulate and form a layer of thin
                            ice on the road. That was tricky to deal with.
                        </TextBody>
                        <Image path={folderPath} name={36}>
                            Giving way to my friends in the forest
                        </Image>
                        <TextBody>
                            The dinner place I went to was closed. It was in a campground, and I think the
                            campground had closed for the season. Then I tried a Mexican restaurant, but that was
                            closed too. I learned my lesson and started calling them before I went anywhere. Finally
                            I was able to find a place.
                        </TextBody>
                        <TextBody>
                            There was only one waitress working, and it was rush hour. She was working nonstop, but
                            since this seemed to be the only place open in town besides fast food, I couldn’t
                            complain. A white, middle-aged man gave her a hard time, even though his wife tried to
                            stop him. Afterward, everyone around started talking to the waitress, thanking her for
                            her hard work and consoling her. I made sure to say thank you too. On a Tuesday during
                            Thanksgiving week in a small town, I was very grateful to have a decent meal.
                        </TextBody>
                        <TextBody>
                            It ain’t the end of the day. I made a risky decision to stay in a trailer at an RV park
                            that night. I was just too curious.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[37, 38, 39]} ratio={[6, 3, 3]}>
                            The trailer I was staying
                        </ImageGrid>
                        <TextBody>
                            The trailer had everything, very thoughtful. My host even turned on the heater for me
                            ahead of time. The bathrooms and laundry rooms were outside, and they were very clean as
                            well.
                        </TextBody>
                        <TextBody>
                            Later I stepped outside to use the bathroom and saw some animal across the street, which
                            I thought was a dog at the time. Now, looking at the picture, I’m pretty sure it was a
                            coyote. I don't know why I just can't stop laughing at myself. Of all the choices I had,
                            I decided to stay in a trailer, step out in freezing weather to brush my teeth while it
                            was snowing, with a coyote staring at me. I don’t regret the decision, but maybe next
                            time, I shouldn’t be as curious.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[40, 41, 42]} ratio={[4, 4, 4]}>
                            The RV park
                        </ImageGrid>
                        <TextTitle ref={refs.current[3]}>
                            {titles[3]}
                        </TextTitle>
                        <Image path={folderPath} name={"m4"}>
                            Simple day plan
                        </Image>
                        <TextBody>
                            Still snowing. So there went my plan of going off-road. In this awful weather, I
                            desperately needed a cup of hot coffee. I found a spot on the map and called ahead to
                            make sure they were open.
                        </TextBody>
                        <ImageV path={folderPath} name={43}>
                            Inside the coffee shop
                        </ImageV>
                        <TextBody>
                            It was a lovely coffee shop, and while I was there, I met a Korean couple. The owner
                            proudly mentioned that they were the only coffee place open for the season, and they
                            also had an ice cream shop that was still running. I love it that in freezing weather,
                            with all the other shops, restaurants and campgrounds closed for the season, the ice
                            cream shop was still standing strong.
                        </TextBody>
                        <ImageV path={folderPath} name={44}>
                            I think Christmas is here don't you
                        </ImageV>
                        <TextBody>
                            I met a black stray cat outside the coffee shop. I’m not sure if that’s a sign of luck
                            or not.
                        </TextBody>
                        <TextBody>
                            Went to fill my tank before hitting the road. I could barely stand outside with my
                            jacket on but look at this guy.
                        </TextBody>
                        <ImageV path={folderPath} name={45}>
                            I'd believe you if you say he's from another planet
                        </ImageV>
                        <TextBody>
                            Given the weather, I had to give up all my other plans, which left me with enough time
                            to explore Capitol Reef National Park.
                        </TextBody>
                        <Image path={folderPath} name={46}>
                            Inside the national park. I don't see the castle
                        </Image>
                        <ImageV path={folderPath} name={47}>
                            Bought a silly sheep at Gifford Homestead
                        </ImageV>
                        <TextBody>
                            I went for the scenic drive in the park, but you can't really go far since most of the
                            road was closed. So I made a turn at Grand Wash. It couldn’t hurt to drive a little
                            more. On my way back, I saw the red rock slowly unveiling from the fog, stunning.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[48, 49]} ratio={[6, 6]}>
                            On my way back from scenic drive
                        </ImageGrid>
                        <TextBody>
                            I also hiked the Hickman Bridge Trail. I have no idea why I thought it would be easy
                            with just a 50m elevation gain. I questioned myself constantly as I climbed over rocks
                            using both hands. There was no trail at all, just rocks, endless rocks. That's how I
                            went the wrong way several times and ended up at a cliff again wondering: where is this
                            bridge?
                        </TextBody>
                        <TextBody>
                            The hike wasn’t long, but I took at least a one-mile detour to get back on track.
                            Normally, I have a great sense of direction, but I don’t know what Utah did to me.
                            Thankfully Google Maps’ location was pretty accurate so I had some reference. I’m
                            starting to think that a subscription to AllTrails might be worth it.
                        </TextBody>
                        <ImageV path={folderPath} name={50}>
                            Hickman Bridge
                        </ImageV>
                        <TextBody>
                            The first thing I did when I got back to my car was check the national park map to see
                            how they described this trail. They called it moderate. Okay, so they are not completely
                            insane.
                        </TextBody>
                        <TextBody>
                            My original plan was to check out Factory Butte, Moon Overlook Point, and Long Dong
                            Silver, all of which require off-road driving. I felt bad about having to skip them, but
                            it was the right thing to do. As I was driving out of Capitol Reef National Park, I took
                            a sharp turn and saw this moon-like terrain stretching in front of me. I noticed a
                            cameraman pulling over and hiking uphill with his gear to find the perfect angle. I
                            joined him. I stopped to snap a quick picture. I didn’t dare to go too far.
                        </TextBody>
                        <Image path={folderPath} name={51}>
                            A random pull out point
                        </Image>
                        <TextBody>
                            Moon Overlook Point feels like the standard spot for a classic shot—an easy, expected
                            view anyone can capture. But this, this is what you see while driving, a complete
                            surprise. Views like this are everywhere, you just need to see it with your eyes. I know
                            Moon Overlook is beautiful, but now I don’t feel the need to go there. The shock of this
                            kind of view, how it suddenly appears, and before you know it, you joined the mountains.
                            I was driving among them, it felt like a dream. A single well-staged photo means
                            nothing. This feeling means everything.
                        </TextBody>
                        <TextBody>
                            I made a few more stops at random pull-out points. They weren’t really pull-out points,
                            just tire marks left by other cars by the side of the road. It was a very risky move
                            because the roads were muddy, soft, and slippery. At one point, I almost got trapped.
                            Don’t copy this! I would love to admire the mountains and rocks, go off-road as much as
                            I can, and explore everywhere on a sunny day. But you don’t meet a snowy day with black
                            mountains covered in snow, stripes of smoke hanging halfway up the mountain every day.
                            Different weather has its own beauty.
                        </TextBody>
                        <TextBody>
                            Factory Butte can be seen not far from the main road. When I was there, I noticed the
                            dirt road was mostly covered in gravel. I thought going a little further wouldn’t hurt.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[52, 53]} ratio={[6, 6]}>
                            In front of a toilet near Factory Butte. I was told it's a pretty nice viewpoint
                        </ImageGrid>
                        <TextBody>
                            The road condition was pretty bad. I could feel it the moment I stepped out of the car.
                            I couldn’t stay much longer or drive any further, as it would only get worse. Time to
                            head to my stay for the night in Moab.
                        </TextBody>
                        <TextBody>
                            The drive there was awful. I had to drive through heavy snow for the first half, then
                            heavy rain as I headed south. It was only around 3:30 pm when I arrived at my inn, but I
                            was already exhausted.
                        </TextBody>
                        <Image path={folderPath} name={54}>
                            Drove in this condition for at least an hour
                        </Image>
                        <TextBody>
                            The inn was super nice. I love Moab. Moab feels like a utopia for outdoorsy people. I
                            went to a Mediterranean place for dinner, and OH MY GOD, their food was amazing. Or
                            maybe it was just me starving. Everything I had reminded me of my days in
                            Turkey. And their baklava is everything!
                        </TextBody>
                        <ImageSmall path={folderPath} name={55}>
                            THE baklava
                        </ImageSmall>
                        <TextTitle ref={refs.current[4]}>
                            {titles[4]}
                        </TextTitle>
                        <Image path={folderPath} name={"m5"}>
                            Arches NP day
                        </Image>
                        <TextBody>
                            I'm back on the trails! Spent the whole day at Arches National Park, no need to drive
                            far. Started my day with coffee and breakfast at a local café.
                        </TextBody>
                        <ImageV path={folderPath} name={56}>
                            I don't know what they put in this sandwich but it was too good to be true
                        </ImageV>
                        <TextBody>
                            Started with the Double Arch Trail. It was a flat, easy hike. Double Arch gave me vibes
                            of Yellowstone, with so many people there just for the picture.
                        </TextBody>
                        <Image path={folderPath} name={57}>
                            Double arch
                        </Image>
                        <TextBody>
                            I ran into the Korean couple again at Turret Arch. They had been at Capitol Reef the day
                            before, stayed in Moab, and were visiting Arches that day. It felt nice to see familiar
                            faces in a place that was completely unfamiliar to me. And we happened to share the same
                            itinerary!
                        </TextBody>
                        <TextBody>
                            I had only planned to visit Landscape Arch and wasn’t planning to go any further.
                            However, when I saw the recommended hiking time and realized the distance wasn’t too
                            long, I decided to go for Double O Arch as well. I figured I could still make it back to
                            Delicate Arch in time for sunset.
                        </TextBody>
                        <TextBody>
                            It was foggy when I started the hike. The trail to Landscape Arch was flat and easy.
                            While I was taking pictures with my film camera, a family was standing next to me. Their
                            young son tried to say something, but his mom gently shushed him saying, "She’s doing
                            her thing."
                        </TextBody>
                        <Image path={folderPath} name={58}>
                            Landscape Arch
                        </Image>
                        <TextBody>
                            The hike to Double O Arch from there was 1.1 miles one way. However, the first challenge
                            I faced was a massive rock I had to climb. It wasn’t until I heard kids laughing from
                            the top that I thought to myself, If kids can do it, I can too.
                        </TextBody>
                        <ImageV path={folderPath} name={59}>
                            The rock I had to climb
                        </ImageV>
                        <ImageV path={folderPath} name={60}>
                            Looking down from the top of that rock
                        </ImageV>
                        <TextBody>
                            On my way to Double O Arch, I stepped aside to let a few guys pass who were heading in
                            the opposite direction. Since we were walking on rocks with no clear trail, they found a
                            way around where I was standing. I commented: I see no trails here, and they burst out
                            laughing. Honestly, it wasn’t that funny considering we were the ones struggling through
                            it. I’ve learned I should never take the "1.1 miles" as if it were the same as 1.1 miles
                            on other hikes. I’ve never felt more like a goat, jumping between steep rocks.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[61, 62, 63]} ratio={[4, 6, 2]}>
                            Views on my way
                        </ImageGrid>
                        <TextBody>
                            I lost my way countless times. To be fair, there weren’t any real paths, just rocks,
                            some easier to climb than others. I must defend myself, it was so easy to get lost
                            hiking in Utah. I went the wrong way on the last huge rock I needed to climb over to see
                            the arches. The previous day’s snow made the rocks icy and slippery. I had to slide down
                            a steep slope to be back on track, only to discover that the rock I had just slid from
                            was actually the correct path.
                        </TextBody>
                        <TextBody>
                            While stuck with that rock, I met a kind couple. The girl was exploring ways to go while
                            her partner stayed below, watching out for her. She managed to find a way and helped me
                            as I climbed up. Her partner stayed behind me to ensure I didn’t slip. They asked if I
                            was hiking alone. When I said yes, the girl called me brave. Honestly, I hadn’t known
                            what I was getting into before coming here. The couple continued hiking under the arch
                            to complete the primitive loop. I wasn’t planning to go that far. I took a few quick
                            pictures and prepared to head back.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[64, 65]} ratio={[6, 6]}>
                            View of Double O Arch sitting on the huge rock
                        </ImageGrid>
                        <TextBody>
                            That’s when I met Chris, just as I was putting away my GoPro. “Team GoPro!” he said. He
                            was doing the primitive loop and happened to be heading the same way as me. I joined him
                            for the rest of the hike.
                        </TextBody>
                        <TextBody>
                            He is in the Navy. His wife had hurt her ankle before, so she wasn’t able to do a
                            strenuous hike like this. She stayed in Moab for the day while he left early that
                            morning to complete the loop.
                        </TextBody>
                        <TextBody>
                            The fog lifted and the sky cleared as we made our way back.
                        </TextBody>
                        <Image path={folderPath} name={66}>
                            Still Landscape Arch
                        </Image>
                        <ImageGrid path={folderPath} name={[67, 68]} ratio={[6, 6]}>
                            Partition Arch and Navajo Arch
                        </ImageGrid>
                        <TextBody>
                            He pointed at a branch and asked me, "Do you see the steam rising from the snow? That's
                            beautiful." At that moment, I realized he was a really great hiking buddy.
                        </TextBody>
                        <ImageSmall path={folderPath} name={69}>
                            You can't see the steam that clearly since this is not a gif. But you get the idea
                        </ImageSmall>
                        <TextBody>
                            It wasn't in his plan, but he spontaneously decided to join me for the Delicate Arch
                            sunset hike. We arrived just in time. As we made our way around the mountains and
                            stepped up onto the rocks, we were greeted by the golden arch, with the pink La Sal
                            mountains in the distance.
                        </TextBody>
                        <Image path={folderPath} name={70}>
                            Golden hour at Delicate Arch
                        </Image>
                        <TextBody>
                            Compared to the Double O Arch Trail, the Delicate Arch Trail felt like paradise.
                        </TextBody>
                        <TextBody>
                            We joined everyone else, sitting down to rest and worship the sunset.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[71, 72, 73]} ratio={[4, 4, 4]}>
                            Delicate Arch at sunset
                        </ImageGrid>
                        <TextBody>
                            I helped Chris take a lot of pictures but ended up with none of myself. He offered to
                            take a few for me, but I didn't feel like it. Then he stood behind me, suddenly called
                            my name, and snapped a quick picture.
                        </TextBody>
                        <TextBody>
                            It was completely dark when we headed back to the parking lot. Watching the stars isn’t
                            a luxury in this national park or the surrounding town, but what was lucky for me was
                            seeing Starlink satellites.
                        </TextBody>
                        <ImageV path={folderPath} name={74}>
                            Starlink shot with my iPhone
                        </ImageV>
                        <TextBody>
                            Oh I was so done with hiking.
                        </TextBody>
                        <TextTitle ref={refs.current[5]}>
                            {titles[5]}
                        </TextTitle>
                        <Image path={folderPath} name={"m6"}>
                            Canyonlands NP day
                        </Image>
                        <TextBody>
                            I went to the same café for breakfast simply because it’s so good. While I was there, I
                            overheard someone mentioning chances to see the northern lights that night. I
                            immediately joined the conversation. I was thrilled! I know there have been several
                            spectacular northern light this year, and I’ve seen people posting pictures of them from
                            Moab. The Kp index was indeed high, but I hadn’t checked any other details. I also
                            didn’t recall hearing about strong solar activity in the past few days.
                        </TextBody>
                        <TextBody>
                            Anyway, I ended up chatting with a guy named Nathan. He’s from Australia and calls
                            himself half Chinese. He lived in China for a few years and knows a lot of “deep
                            secrets” that only locals do. Nathan was planning to visit Canyonlands that day too.
                        </TextBody>
                        <TextBody>
                            We didn’t leave together though. I wanted to explore the Needles section, but the ranger
                            told me it wasn’t worth it unless I planned to hike. So I decided to stick to the Island
                            in the Sky section, the most popular area with lots of viewpoints and minimal hiking.
                        </TextBody>
                        <TextBody>
                            It was such a coincidence that I ran into Nathan again at Shafer Canyon Overlook.
                        </TextBody>
                        <ImageV path={folderPath} name={75}>
                            Shafer Canyon Overlook
                        </ImageV>
                        <TextBody>
                            There was an off-road drive I could go for, through which you can drive from the
                            national park back to Moab. The problem was, it had just snowed a few days ago, the
                            road was icy. And the road was narrow, once you go for it, there’s no turning back. I
                            wanted to try it but it felt too risky. We saw someone attempt it. They were driving
                            down the hill slowly, moving forward inch by inch with extreme caution. We stood on the
                            cliff watching, wondering how much they were regretting it and how many cars had
                            probably lost control and gone over that edge.
                        </TextBody>
                        <ImageV path={folderPath} name={76}>
                            Part of the drive. You can see a green car there
                        </ImageV>
                        <TextBody>
                            Nathan insisted that, after all the pictures I’d taken of the scenery, I needed one with
                            myself in it. So, he took this photo. He said, "I’ve been into photography for over 20
                            years. Trust me."
                        </TextBody>
                        <Image path={folderPath} name={77}>
                            Me cause I didn't want to show my face
                        </Image>
                        <TextBody>
                            Went through the viewpoints and did two short hikes: Grand View Point Overlook and
                            Upheaval Dome Overlook.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[78, 79, 81]} ratio={[5, 3, 4]}>
                            Mesa Arch, Buck Canyon Viewpoint and Orange Cliffs Overlook
                        </ImageGrid>
                        <ImageGrid path={folderPath} name={[80, 82, 83]} ratio={[5, 4, 3]}>
                            Grand View Point Overlook
                        </ImageGrid>
                        <TextBody>
                            After visiting all the viewpoints, I decided to return to Shafer Canyon Overlook for
                            sunset. It has view of both the canyon and the snowy La Sal Mountains. By the time I
                            finished the hike to Upheaval Dome, golden hour was already underway. I rushed to get
                            there before the pink glow on the snow-covered mountains disappeared. On my way, at a
                            random pullout point that’s not even on the map, I got the best view.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[84, 85]} ratio={[6, 6]}>
                            Sunset at Canyonlands
                        </ImageGrid>
                        <TextBody>
                            That day was Thanksgiving. Moab is a relatively bigger town with more options, so I
                            stayed for dinner before heading to Blanding to spend the night. After calling tons of
                            restaurants, I finally found one that was open. Later, Nathan joined me and showed me
                            pictures of the chipmunks he'd spotted earlier. He’s obsessed with them.
                        </TextBody>
                        <TextBody>
                            No luck with the northern lights, or maybe I should say it was as I expected. Seeing
                            them here is extremely rare.
                        </TextBody>
                        <TextBody>
                            I really don’t think Blanding is a very friendly town, though it’s bigger than the
                            others I passed through. Both the city and my inn creep me out.
                        </TextBody>
                        <TextTitle ref={refs.current[6]}>
                            {titles[6]}
                        </TextTitle>
                        <Image path={folderPath} name={"m7"}>
                            Quite-some-drive day
                        </Image>
                        <TextBody>
                            Breakfast! Nothing was open in my town. It gave me the vibe that zombies had destroyed
                            it, eating everyone, tearing down the buildings, and leaving behind an empty ghost town.
                            I escaped as fast as I could. I found a place nearby called Twin Rocks Café. Thank you
                            for being open. THANK YOU!!! I was completely over fast food.
                        </TextBody>
                        <ImageV path={folderPath} name={86}>
                            I guess this is why it's called Twin Rocks Café
                        </ImageV>
                        <TextBody>
                            The food was amazing, but that's not the point I'm trying to make. In front of the
                            cafe's stairs, there are handprints with dates next to them. Seems like this is a
                            family-run business. Lovely.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[87, 88, 89]} ratio={[3, 5, 4]}>
                            Opal and Pearl, and the handprints
                        </ImageGrid>
                        <TextBody>
                            Okay, Monument Valley. I was exhausted at this point. This trip is finally coming to an
                            end.
                        </TextBody>
                        <Image path={folderPath} name={90}>
                            Mexican Hat on my way to Monument Valley
                        </Image>
                        <TextBody>
                            Still one more stop to make: the exact camera location where Forrest Gump ended his run.
                            By the way, Forrest Gump is a fantastic movie, go watch it if you haven’t already. I’ve
                            always loved stories about insignificant people, though I’m not sure if it’s fair to
                            call Forrest "insignificant."
                        </TextBody>
                        <ImageV path={folderPath} name={91}>
                            Yes I didn't wait in line for THE shot. I'm fine with it
                        </ImageV>
                        <TextBody>
                            Now it’s really Monument Valley time. The plan was simple: drive along the scenic route.
                            The road turned out to be much bumpier than I expected. I truly admire those who drove
                            Tesla here. Monument Valley is undoubtedly breathtaking at sunset. Starting the scenic
                            drive around 2 pm would let you catch it at the perfect moment. But I had something else
                            planned for that day.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[92, 93, 94]} ratio={[5, 2, 5]}>
                            Monument Valley
                        </ImageGrid>
                        <Image path={folderPath} name={95}>
                            And Monument Valley
                        </Image>
                        <TextBody>
                            I can’t even describe how rough the off-road scenic drive was. Let me put it this way: I
                            was driving a car designed for conditions like this, I got carsick and almost threw up
                            afterward. For those who say White Pocket is tough to drive, hello? Have you ever been
                            to Monument Valley? The view was absolutely worth it though.
                        </TextBody>
                        <TextBody>
                            The drive took way longer than expected, and I still had to rush to Shiprock for sunset.
                            I needed to arrive 30 mins before sunset for the golden hour. Compared to people in
                            Utah, New Mexico drivers drive like there’s no tomorrow. What’s wrong with you guys?
                            Well I should be used to this since I came from Texas. But what’s wrong with you?
                        </TextBody>
                        <TextBody>
                            I didn’t have time to see Shiprock last time I was in New Mexico, so this time I made a
                            300-mile detour for it. Shiprock and the surrounding land hold both religious and
                            historical significance to the Navajo people.
                        </TextBody>
                        <TextBody>
                            Make a turn onto Indian Service Rte 5010 to get closer to Shiprock. I read that there’s
                            a sign somewhere along the way saying, "Be respectful to local environments and culture,
                            don’t drive closer to the rock." I didn’t see the sign, but I didn’t drive any further.
                            I just parked in the open ground next to the main road.
                        </TextBody>
                        <TextBody>
                            I think I made it just in time.
                        </TextBody>
                        <ImageV path={folderPath} name={96}>
                            Shiprock at golden hour
                        </ImageV>
                        <TextBody>
                            My hands were shaking as I parked the car, gathered all my camera gear, and jumped out
                            to take this photo. 300 miles? I'd totally do it again.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[97, 98]} ratio={[6, 6]}>
                            The pictures were taken 5 minutes apart. Sunlight faded in that time
                        </ImageGrid>
                        <TextBody>
                            I wanted to have dinner in the town Shiprock, but the gas station I stopped at seemed
                            really sketchy. I escaped after filling up my tank. Later, I found out the energy drink
                            I bought there had expired over six months ago.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[99, 100]} ratio={[4, 8]}>
                            There were broken parts of cars on the ground
                        </ImageGrid>
                        <TextBody>
                            I stayed at a motel in Gallup, which was my first time staying at one. I was really
                            worried beforehand since I wasn’t familiar with the city, and it was New Mexico. I did a
                            lot of research and chose the one I ended up staying at. To my surprise, the motel was
                            family-run and super nice.
                        </TextBody>
                        <TextTitle ref={refs.current[7]}>
                            {titles[7]}
                        </TextTitle>
                        <Image path={folderPath} name={"m8"}>
                            Ready to go back to Phoenix
                        </Image>
                        <TextBody>
                            Petrified Forest is a chill national park, not too big, you can cover most of the scenic
                            views without much hiking. This is Celeste’s favorite place. I’ll let the photos speak.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[101, 102]} ratio={[6, 6]}>
                            Painted Desert
                        </ImageGrid>
                        <Image path={folderPath} name={103}>
                            Blue Mesa
                        </Image>
                        <ImageV path={folderPath} name={104}>
                            Petrified Forest National Park is the only park in the National Park System containing a
                            section of Historic Route 66
                        </ImageV>
                        <ImageGrid path={folderPath} name={[105, 106, 107]} ratio={[3, 5, 4]}>
                            My favorite trail, Crystal Forest, with petrified wood scattered along the way
                        </ImageGrid>
                        <TextBody>
                            It’s totally illegal to take petrified wood from the park. If you’d like a piece, you
                            can buy one at the gift shop or visitor center.
                        </TextBody>
                        <TextBody>
                            It was still too early to check in at my Airbnb in Cottonwood when I left the national
                            park. As I looked over the map for things to do on the way, I came across Meteor Crater.
                            Nathan had mentioned it before, and I read some Google reviews saying it wasn’t a
                            tourist trap. Plus, I had never seen a meteor crater before, aside from Upheaval Dome.
                        </TextBody>
                        <TextBody>
                            I shouldn’t have been so curious. It felt like a tourist trap, and I should have turned
                            around the moment I saw the $29 ticket price. I mean there was an exhibit explaining the
                            basics of meteors and craters, but a national park charges $35 per vehicle, and they’re
                            asking $29 per person here? Plus, you’re not even able to walk around the rim of the
                            crater. I will never forget the number 29. I have hate in my eyes just thinking about
                            it. If I had the chance to choose again, I would spend some time in Holbrook, and waste
                            that $29 on coffee and good food.
                        </TextBody>
                        <ImageSmall path={folderPath} name={108}>
                            The only picture I have for Meteor Crater
                        </ImageSmall>
                        <TextBody>
                            Last night of my trip and the final day of November. Laura, my host, showed me her
                            little 'Apple collection.' She told me that when she used to work for Apple, her manager
                            once asked if anyone wanted this trash, so she took them home.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[109, 110, 111]} ratio={[4, 4, 4]}>
                            She was so excited while talking about them. She said, 'You know, Wozniak handwrote
                            these numbers.'
                        </ImageGrid>
                        <TextBody>
                            Went to an Italian restaurant she recommended. She said Cottonwood is a very Western
                            American small town and that I would like it. Old Town Cottonwood was quiet, but the
                            restaurant was packed with people, talking, laughing, cheering.
                        </TextBody>
                        <ImageSmall path={folderPath} name={112}>
                            A nearby bar
                        </ImageSmall>
                        <TextTitle ref={refs.current[8]}>
                            {titles[8]}
                        </TextTitle>
                        <TextBody>
                            There was a guestbook in my room, apparently provided by Airbnb. I love how they put it:
                            Belong anywhere. I felt it.
                        </TextBody>
                        <ImageSmall path={folderPath} name={113}>
                            The guestbook
                        </ImageSmall>
                        <TextBody>
                            Nothing much to say. Drove back to Phoenix, got a hit-and-run by crazy people, returned
                            my car, and waited for my flight. Sunset that day was beautiful.
                        </TextBody>
                        <ImageV path={folderPath} name={114}>
                            Sunset at the airport
                        </ImageV>
                        <TextBody>
                            I drove over 2,000 miles in 8 days, it was one hell of a ride. Thanks to RAYE and Tove
                            Lo for hyping up my trip, thanks to Wrangler for being so tough, and thanks to myself
                            for creating this crazy itinerary and making it through. I’d like to end this story with
                            a line from Claudia by FINNEAS: You don’t even understand, just how far I drive.
                        </TextBody>
                    </Box>
                    <MenuButton open={openDrawer(true)}></MenuButton>
                    <Drawer open={open} onClose={openDrawer(false)}>
                        {<MenuDrawer isMobile={isMobile} func={openDrawer(false)} titles={titles} refs={refs}/>}
                    </Drawer>
                </Box>

            }
        </Box>
    )
};

export default Utah;
