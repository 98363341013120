import {Typography, useMediaQuery} from '@mui/material';
import Box from "@mui/material/Box";
import React from "react";
import Drawer from "@mui/material/Drawer";

import TextBody from "./modules/textBody";
import TextTitle from "./modules/textTitle";
import Image from "./modules/image";
import Music from "./modules/music";
import ImageV from "./modules/imageV";
import ImageSmall from "./modules/imageSmall";
import ImageGrid from "./modules/imageGrid";
import PreviewCard from "./modules/previewCard";
import MenuButton from "./modules/menuButton";
import MenuDrawer from "./modules/menuDrawer";

const EastCoast = ({ isPreview }) => {
    const [open, setOpen] = React.useState(false);

    const openDrawer = (value) => () => {
        setOpen(value);
    };

    const isMobile = useMediaQuery('(max-width:768px)');

    const titles=[
        'Boston',
        'Rhode Island',
        'New York',
        'Before New Year'
    ];
    const refs = React.useRef(Array.from({ length: titles.length }, () => React.createRef()));

    const folderPath="eastCoast";

    return (
        <Box>
            {isPreview &&
                <PreviewCard path={folderPath}
                             title={"East coast impression"}
                             date={"January 2, 2025"}
                             isMobile={isMobile}>
                    I’ve never been an East Coast girl
                </PreviewCard>
            }
            {!isPreview &&
                <Box className="content" sx={{width: '80%', margin: '0 auto'}}>
                    <Typography variant={isMobile ? "h4" : "h3"} className='blogTitle'>East coast impression</Typography>
                    <Typography color="textSecondary" className='blogDate' gutterBottom>
                        January 2, 2025
                    </Typography>
                    <Box className='bolgContent'>
                        <Music src={"https://open.spotify.com/embed/track/0LA0F3wVatT3z5bSW8qyyn?utm_source=generator"}
                               title={"Personal Heaven by Michael Medrano"}/>
                        <TextBody>
                            I think this is a bit of a weird song choice, but I’ll just go with it. I’ve been listening to it heavily lately.
                        </TextBody>
                        <TextBody>
                            The last time I was on the East Coast, I stopped by Newark to catch a connecting flight to Iceland about two years ago. I only ran through the airport, didn’t actually set foot on East Coast land. If we’re talking about the “real” last time, it was over 8 years ago back in high school. I joined a tour group that spent two weeks in California and a week visiting major cities on the East Coast. By the way that was when my love for California started, I had a wonderful homestay in Los Angeles, but that’s a totally different story.
                        </TextBody>
                        <TextBody>
                            Once again, I wasn’t sure where to go for the holiday season. A friend invited me to Boston, so I figured I’d make it a revisit to the East Coast, even though I’m really not a huge fan.
                        </TextBody>
                        <TextBody>
                            Claiming it now, don’t expect any plot twists. I’m still not a big fan of the East Coast. I really think the West Coast is where I thrive.
                        </TextBody>
                        <TextTitle ref={refs.current[0]}>
                            {titles[0]}
                        </TextTitle>
                        <TextBody>
                            When I just hopped on the plane, the pilot said that Boston was snow showering and promised to get us there as soon as possible. When we landed, I saw the conditions and couldn’t believe my flight hadn’t been canceled. The airport was nearly empty. I assume most other flights were canceled.
                        </TextBody>
                        <TextBody>
                            It was still snow showering when I went to pick up my rental, a FWD sedan, and I hesitated. I almost didn’t go. While I’ve driven in conditions like this before, it was always in a more capable vehicle. The roads were rough, fresh snow blanketed the ground and hadn’t been plowed yet. I almost couldn’t make one of the small hills. My wheels spun like crazy, barely moving. So why did I decide to drive this car? Well, back where I used to live, winters were like this all the time. If other people could handle it, I figured so could I.
                        </TextBody>
                        <TextBody>
                            I’ve always had friends telling me I’m courageous, and I’d always deny it. But now, I’m starting to believe it might actually be true ;).
                        </TextBody>
                        <ImageGrid path={folderPath} name={[1, 12, 13]} ratio={[4, 4, 4]}>
                            It's beautiful when snowing isn't it?
                        </ImageGrid>
                        <TextBody>
                            With nothing else to do in this weather, especially impossible for outdoor activities, my friends and I went to the Museum of Fine Arts. I’m not exactly the artsy type, and I’m terrible at history. I don’t really get most of the pieces in the museum, how they stand out or represent a specific time. I just look for the ones I find interesting, hoping I can take some positive influence from these works I don’t fully understand. This is my favorite museum so far, especially because of how the exhibit decorations match the themes. Here are some of my favorite pieces.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[4, 5, 6]} ratio={[4, 4, 4]}></ImageGrid>
                        <ImageGrid path={folderPath} name={[3, 7, 8]} ratio={[4, 4, 4]}></ImageGrid>
                        <ImageGrid path={folderPath} name={[9, 2, 10]} ratio={[4, 4, 4]}>
                            Arts I like
                        </ImageGrid>
                        <ImageSmall path={folderPath} name={11}>
                            I would like to call this piece "WTF???". Love it so much
                        </ImageSmall>
                        <ImageGrid path={folderPath} name={[16, 17]} ratio={[4, 8]}>
                            Trees outside the museum
                        </ImageGrid>
                        <ImageSmall path={folderPath} name={18}>
                            A very interesting vessel, I may try making this one day
                        </ImageSmall>
                        <TextBody>
                            I also went to the Institute of Contemporary Art. Honestly, I knew I wouldn’t understand most of the art before I went, and that was the actual case. What I loved about it though, is that the museum is by the sea. With all the huge windows, you get a beautiful view of the harbor. Traveling around the whole Boston area, I never came across a restaurant or coffee shop with a view like this. That’s one of the reasons I’m a bit disappointed in Boston.
                        </TextBody>
                        <Image path={folderPath} name={21}>
                            Outside the Institute of Contemporary Art. I think it looks a lot like Oslo
                        </Image>
                        <ImageGrid path={folderPath} name={[22, 23, 24]} ratio={[3, 6, 3]}></ImageGrid>
                        <ImageGrid path={folderPath} name={[25, 26]} ratio={[7, 5]}>
                            Looking out of the windows
                        </ImageGrid>
                        <TextBody>
                            Called my dear friends in Indonesia one morning, who were in the same volunteer project as me almost 6 years ago. We hadn’t talked in a while, but we’ve never lost touch! Exchanged a lot about our recent lives with this sunrise. You know it’s pretty difficult to find a time we can talk, especially when we’re on opposite sides of the earth.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[27, 28]} ratio={[4, 8]}>
                            Sunrise that morning
                        </ImageGrid>
                        <TextBody>
                            I saw a lot of Boston Tea Party elements throughout the city.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[19, 20]} ratio={[5, 7]}>
                            The tea is pretty good
                        </ImageGrid>
                        <TextBody>
                            Went to the Freedom Trail later cause my friend wanted to. Honestly, I wasn’t interested at all. All the places and scenes along the way seemed too touristy. The food at Quincy Market was mostly fast food, and I didn’t find anything particularly unique to Boston, except for the lobster. The North End wasn’t what I expected, and there wasn’t much to see by the sea either. Crossing the bridge, Bunker Hill felt more residential.  So by far my impression for Boston is still not good.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[29, 30]} ratio={[7, 5]}>
                            Trail marker and sunset on Bill Russell Bridge
                        </ImageGrid>
                        <TextBody>
                            I went separate ways with my friends later. They headed to Los Angeles while I stayed in Boston for Christmas.
                        </TextBody>
                        <TextBody>
                            Went for a walk at Boston Common after my fancy Christmas Eve dinner. To my surprise, there were quite many people there. Dogs running, kids running, couples holding hands, families taking pictures. Came across two churches, lovely atmosphere, lots of people chatting. I felt like I didn’t belong there even though there were signs in front of the church: All are welcome. I think I don’t belong to the crowd. I love this lively vibe. It was probably the first time in days I felt that this city was composed of people, actual living people, not concrete buildings and steel vehicles.
                        </TextBody>
                        <TextBody>
                            I stayed at an Airbnb in Cambridge. Knowing that nowhere would be open during Christmas, I went out for a random walk. My place was about 30 minutes’ walk from both Harvard and MIT. Harvard is surrounded by fences, which makes it feel unlike a typical U.S. campus. The buildings were close to each other, the trees were bare and the grass was covered with snow, really not very attractive. There were homeless sleeping right at the corner of the block next to campus. There were always people on campus taking pictures, most of them were Asian parents with their kids.
                        </TextBody>
                        <ImageV path={folderPath} name={31}>
                            At Harvard. This is how you should have fun
                        </ImageV>
                        <TextBody>
                            Probably because I’m an engineering student, I found MIT extremely attractive. The overall atmosphere of the campus, the architectural styles of the buildings, and even the labs visible through the windows, everything was so charming. MIT made me want to study here immediately (kidding, I’m not smart enough).
                        </TextBody>
                        <ImageGrid path={folderPath} name={[32, 33, 34]} ratio={[3, 4, 5]}>
                            At MIT
                        </ImageGrid>
                        <TextBody>
                            I tried to find something interesting to do and most importantly, still open. Newbury Street was my lucky find. It reminded me of Huaihai Road in Shanghai, one of my favorite places to hang around.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[35, 36, 37]} ratio={[3, 5, 4]}>
                            Random street view, not all on Newbury Street
                        </ImageGrid>
                        <TextBody>
                            I felt like there must be an overlook place somewhere and I found this View Boston on the map. It turned out to be my most surprising find. I was there right before sunset, stayed till dark. It was beautiful, truly beautiful. Even if you are just there for sunset, not the city overview at all, it was still worth it.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[38, 39]} ratio={[8, 4]}>
                            On viewing deck
                        </ImageGrid>
                        <ImageV path={folderPath} name={"40"}>
                            Probably one of my best takes. He is a very kind staff
                        </ImageV>
                        <TextBody>
                            I have to make some space for Canada Goose. They are everywhere!
                        </TextBody>
                        <ImageGrid path={folderPath} name={[41, 42, 43]} ratio={[4, 4, 4]}>
                            Canada Goose on every corner
                        </ImageGrid>
                        <TextBody>
                            I do feel that Boston people are a lot more indifferent compared to those in other places. I had no small talks with uber drivers or anyone at all. Saying “How are you” was the most they can do. My stereotype about Boston drivers is that they’re crazy, driving a Honda or Toyota, squeezing into every possible gap. I was so shocked watching my driver work his magic. I can never drive like that.
                        </TextBody>
                        <TextBody>
                            People also don’t make eye contact with you in this weird place. They don’t smile or nod at you when passing by. They pretend you don’t exist and just walk away. Sometimes I even doubt if they are Americans at all. I wore an ugly and funny sweater on those Christmas days, not even one single person stopped and said “nice sweater”. I’d say they also have no taste.
                        </TextBody>
                        <TextTitle ref={refs.current[1]}>
                            {titles[1]}
                        </TextTitle>
                        <TextBody>
                            A weekend trip. We spent most of our time around Newport and Cranston.
                        </TextBody>
                        <TextBody>
                            Went to Marble House and the Elms, and hung around Newport downtown. Not much to say, I wasn’t too impressed. The Cliff Walk feels nothing compared to those cliffs on the west coast. Probably it was just the wrong season to visit. Downtown has some interesting stores.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[44, 45, 46]} ratio={[4, 4, 4]}>
                            Inside the Mansions
                        </ImageGrid>
                        <ImageGrid path={folderPath} name={[47, 48, 49]} ratio={[3, 5, 4]}>
                            Inside and outside the Mansions
                        </ImageGrid>
                        <ImageV path={folderPath} name={"50"}>
                            Newport Harbor
                        </ImageV>
                        <TextBody>
                            Roger Williams Park Zoo was our surprise find. Not saying how fancy this zoo is or how many rare animals they have. As you can imagine, it was freezing and there were barely any animals outside. All the staff there were very chatty, eagerly sharing stories of the animals with you. We saw a peacock running around freely so we asked some staff if it was normal. We thought he escaped or something. They said yes, he’s a free peacock who can go wherever he likes. He loves to stay close to the heater during weather like this. “He’s not stupid!”
                        </TextBody>
                        <ImageV path={folderPath} name={"51"}>
                            The peacock
                        </ImageV>
                        <TextBody>
                            Saw zookeepers patiently feeding elephants one small piece of vegetable at a time, using food to encourage them to exercise more. We even saw them treating a potential wound on an elephant’s foot. Watching all of these felt like meditation, you can finally breathe when you are close to nature(not entirely), close to vivid living creatures.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[52, 53, 54]} ratio={[4, 4, 4]}>
                            The elephants and their neighbors
                        </ImageGrid>
                        <TextBody>
                            These two parrots were born and raised in the zoo, so they never learned how to fly. Instead, they’ve learned a unique skill, doing flips by clinging to branches.
                        </TextBody>
                        <ImageV path={folderPath} name={55}>
                            The two parrots
                        </ImageV>
                        <TextBody>
                            That area is more residential, without fancy stores and restaurants. I actually love it more for that.
                        </TextBody>
                        <TextTitle ref={refs.current[2]}>
                            {titles[2]}
                        </TextTitle>
                        <TextBody>
                            How do I say this, I hate New York. It’s not that I can’t handle big city modern life, I love Shanghai. It’s New York itself that’s too overwhelming. I didn’t like it the first time I was here and I still don’t like it after this visit.
                        </TextBody>
                        <TextBody>
                            I can only forgive New York on rare occasions: when in a good bar or in museums.
                        </TextBody>
                        <TextBody>
                            I don’t know what was wrong with me, I took the bus from Boston to New York. It was neither cheap nor time-saving. I’m not complaining and saying you shouldn’t take a bus. Though I blame myself for being too curious, I also partially blame Adam Lambert for this. In his song Ready To Run, there’s a line: Catch a bus, Greyhound. So I always wonder what taking a Greyhound feels like. Maybe it’s still on me, I don’t know. I went through some bus trip head-ups online and went prepared. Nothing bad happened, people were all minding their own business. The “worst” part was probably me being the only Asian at the station surrounded by black people, they were checking up on me. Also traffic around Manhattan is awful. We made a detour to New Jersey to get to Port Authority. Flying to LaGuardia or JFK or even Newark would have been way easier cause you can have easy access to public transportation.
                        </TextBody>
                        <TextBody>
                            Ok public transportation. I’ve heard so many crazy things about the New York subway and seen wild videos online. In fact I barely take public transportation in the U.S. unless I have to. I had the worst impression of the New York subway before I went. It turned out better than I thought. At least the trains are running. Not that much trash on the ground, barely homeless in the station, and you can see all kinds of people taking the subway. But, BUT! After a few days, the subway started wearing me down. The waiting time, the random crazy people on the platform, and the smell! The smell kept haunting me. I can even imagine the smell of it when I was already at home lying in bed. The smell seemed to be stuck in my hair. As for crazy people, the craziest I met was dancing and singing on the edge of the platform and on the train, going around places. It took me some effort to stay away from him.
                        </TextBody>
                        <TextBody>
                            The New York subway wasn’t too peaceful by the end of 2024. There were random attacks and people being pushed onto the tracks. Some of these places were close to me, one massive shooting happened right next to the station I went to every day. It is awful, it is so wrong. People including me, can only be glad that it’s a rather small possibility incident and that it didn’t happen to us.                        </TextBody>
                        <TextBody>
                            I was meeting a friend the night I arrived. After settling down, I wasn’t sure where to go, so I figured, Times Square couldn’t go wrong. I was so wrong. The crowd was overwhelming and intimidating. I didn’t have the energy to stop for pictures or admire the beauty of tens and hundreds of massive screens. I just wanted to run. Even though I was a tourist myself, I found that many tourists terrifying.
                        </TextBody>
                        <TextBody>
                            The turning point was when I walked close to Macy’s, there was a Christmas market and they had Glühwein. One hot drink of Glühwein, I can forgive the world. The meaning of winter is hot chocolate and Glühwein. It was crazily expensive though. I mean it’s New York.
                        </TextBody>
                        <ImageV path={folderPath} name={56}>
                            The Glühwein to comfort me
                        </ImageV>
                        <TextBody>
                            The first New York museum to earn my forgiveness was the MET. I had wanted to visit it for a long time. Still, a lot of people, intimidating, but I really love some of the collections. I didn’t go through every single exhibition which was actually a tiny regret for me cause it’s too big. But yes, I found something I love.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[57, 58, 59]} ratio={[4, 4, 4]}></ImageGrid>
                        <ImageGrid path={folderPath} name={[60, 61, 62]} ratio={[4, 4, 4]}></ImageGrid>
                        <ImageGrid path={folderPath} name={[63, 64, 65]} ratio={[4, 4, 4]}>
                            Some arts that I love
                        </ImageGrid>
                        <TextBody>
                            I have to talk about this exhibit. This exhibit was not on, the shelf was empty with only this board on the side. But I felt it was a very interesting topic. I’m learning pottery and what bothers me is the pursuit of perfection in pottery. It’s an achievable but very difficult goal that can make you struggle and painful. I saw and learned designs purposefully break this perfection before, altering existing forms. That’s why I love this show featuring people exploring imperfections. I hope the exhibit was here.
                        </TextBody>
                        <ImageV path={folderPath} name={66}>
                            Only seeing the subject is enough. No need to read the whole thing
                        </ImageV>
                        <TextBody>
                            The other one would be MoMA. San Francisco MoMA has a lot of exhibitions that I don’t understand at all, but I love one particular show. It’s called The Visitors by an Icelandic band. I had high expectations for New York MoMA plus I know they have some famous works. It did not disappoint me.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[67, 68, 69]} ratio={[4, 4, 4]}>
                            Two works on the right are from the same photographer. I love the middle one a lot.
                        </ImageGrid>
                        <ImageGrid path={folderPath} name={[70, 71, 72]} ratio={[4, 4, 4]}>
                            So many people in front of the paintings just for the picture, especially Starry Night. People are acting as if other paintings by Cézanne or Matisse don't exist at all.
                        </ImageGrid>
                        <Image path={folderPath} name={73}>
                            My Favorite
                        </Image>
                        <TextBody>
                            Nothing more to say. Some random street views.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[74, 75, 76]} ratio={[3, 3, 6]}>
                            New York not that typical street view
                        </ImageGrid>
                        <TextBody>
                            Went to visit my friend in Long Island. Port Jefferson was my favorite. This felt like the right place for human beings to live in. Exactly the small-town vibe I like. We went to have custom tea, took a walk by the dock, saw people waiting in line to board the ferry to Bridgeport, and toasted free marshmallows by the street. I don’t know who came up with the free marshmallow idea but she/he is an absolute genius. Went for kava drinks after dinner and played Cards Against Humanity with my friend. I still don’t know what can kava do and if it’s legal or not.
                        </TextBody>
                        <ImageGrid path={folderPath} name={[77, 78, 79]} ratio={[4, 4, 4]}>
                            I have a Master's degree in toasting marshmallow
                        </ImageGrid>
                        <TextBody>
                            I really don’t have much impression of any other things. I only remember taking the train and subway taking forever to get everywhere and transferring at Jamaica every single time. The district around his school was so quiet but the only coffee shop was packed with students. It was raining on New Year’s Eve, my friend and I stayed at home watching the live countdown from Times Square. I wish I was somewhere with fantastic fireworks.
                        </TextBody>
                        <TextBody>
                            I was sick and dealing with a bad allergy before I left, so you really can’t blame me for not remembering or enjoying things. The only thing I wanted was to go home.
                        </TextBody>
                        <TextTitle ref={refs.current[3]}>
                            {titles[3]}
                        </TextTitle>
                        <TextBody>
                            Landed in Houston at night. My Lyft driver started talking to me immediately after I got in the car. He’s Puerto Rican. His first question was: you have kids? I was so shocked. Please look at me! he was talking nonstop, asking me questions, sharing about his new year, his children and grandchildren. he said “You are sick? Drink tequila!” I was not annoyed at all. I know this is how Houston people are, they are naturally welcoming and full of enthusiasm. Every time I jump in the car back home, I already felt home. Now this is how things should be between people.
                        </TextBody>
                        <TextBody>
                            People often wrap up the year with reflections and New Year’s resolutions. I used to be one of them. Weirdly this year, I barely have things to say. This has been such a complicated and chaotic year that it feels impossible to sum it up with few sentences or pictures. I don’t even remember most of those times, last February feels like ages ago. Too many things jammed in this year that it felt much longer than it should have. I can’t say I’ve had great luck, but I can’t say I’ve had terrible luck either. I suppose this is just an ordinary people’s life. I never imagined my life would be like this. In fact I didn’t picture anything at all. Everything in front of me is so unknown and unpredictable. Though I always say let’s see where life leads me, I did picture something nicer. But here we are, I struggled through and am now raising a glass to celebrate some sort of closure.
                        </TextBody>
                        <TextBody>
                            People like to say, I’m ready for the new year, I’m ready to embrace whatever comes to me. I used to say that too, not this year though. After all the ups and downs and sleepless nights, I think I’m way ready for everything. You know it’s just another sunset and another sunrise, life doesn’t restart, life continues.
                        </TextBody>
                        <TextBody>
                            This year started off like a blank sheet of paper.
                        </TextBody>
                        <TextBody>
                            I would love to share my favorite moment from the end of this year. I was at a bar with a friend, they had pretty good cocktails. While we were leaving, the bartender poured limoncello for every bartender as well as for my friend and me. Five of us raised our glasses, cheered, and wished each other a happy New Year. It never felt more like New Year’s.
                        </TextBody>
                        <ImageSmall path={folderPath} name={80}>
                            Drinks and snack we had in that bar
                        </ImageSmall>
                        <TextBody>
                            Despite all of these, I’m finally back at my safe place. I would love to end this blog with a scene from my favorite episode of my favorite show. A heartfelt “Happy New Year”.
                        </TextBody>
                        <Image path={folderPath} name={"sh"}>
                            A Scandal in Belgravia, Season 2 Episode 1 of Sherlock Holmes
                        </Image>
                    </Box>
                    <MenuButton open={openDrawer(true)}></MenuButton>
                    <Drawer open={open} onClose={openDrawer(false)}>
                        {<MenuDrawer isMobile={isMobile} func={openDrawer(false)} titles={titles} refs={refs}/>}
                    </Drawer>
                </Box>
            }
        </Box>
    )
};

export default EastCoast;
