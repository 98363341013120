import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import React from "react";

const Image = ({ children, path, name }) => {
    return (
        <Box>
            <Box
                component="img"
                className='blogImage'
                src={`${process.env.PUBLIC_URL}/blog/${path}/${name}.jpg`}
            />
            <Typography variant="caption" className='blogCaption'>
                {children}
            </Typography>
        </Box>
    );
};

export default Image;
