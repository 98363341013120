import MenuIcon from "@mui/icons-material/Menu";
import {IconButton} from "@mui/material";
import React from "react";

const MenuButton = ({ open }) => {
    return (
        <IconButton
            onClick={open}
            variant="contained"
            sx={{
                position: 'fixed',
                bottom: 20,
                right: 70,
                zIndex: 1000,
                backgroundColor: 'rgba(255, 255, 255, 0.85)',
                "&:hover": {backgroundColor: 'rgba(229,229,229,0.85)'}
            }}
            id="back-to-top-btn"
        >
            <MenuIcon sx={{fontSize: 30}}/>
        </IconButton>
    );
};

export default MenuButton;
