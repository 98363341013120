import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import {scrollToSection} from "./utils";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import React from "react";


const MenuDrawer = ({ isMobile, func, titles, refs }) => {
    return (
        <Box sx={{ width: isMobile ? 200 : 300 }} onClick={func}>
            <List>
                {titles.map((text, index) => (
                    <ListItem key={'D' + index}>
                        <ListItemButton onClick={() => scrollToSection(refs.current[index])}>
                            <ListItemText primary={text} className="listFatherSubheader" disableTypography/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default MenuDrawer;

