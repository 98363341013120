import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";
import {Typography, useMediaQuery} from "@mui/material";

// Dynamically load all blog files from the `blogs` folder
const requireBlogPosts = require.context('../blogs', false, /\.js$/);

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);

    const isMobile = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        const fileNames = [
            './eastCoast.js',
            './thanksgivingInUtah.js',
            './iceland.js',
            './tripsToOregon.js',
            './hi.js',
        ];
        const blogFiles = fileNames.map((fileName) => {
            const blogId = fileName.replace('../', '').replace('.js', '');
            const blogModule = requireBlogPosts(fileName);
            return { blogId, BlogModule: blogModule.default };
        });

        setBlogs(blogFiles);
    }, []);

    return (
        <Box sx={{width: isMobile ? '95%' : '60%', margin: '0 auto'}}>
            <Typography sx={{marginLeft: '5%', paddingBottom: '1%', opacity: 0.38, fontSize: '0.8rem', paddingTop: isMobile? '5%':0}}>Last Update: Jan 11, 2025</Typography>
            {blogs.map(({ blogId, BlogModule }, index) => (
                <Link to={`../blogs/${blogId.toLowerCase()}`} key={blogId} style={{ textDecoration: 'none' }}>
                    <BlogModule isPreview={true}/>
                </Link>
            ))}
        </Box>
    );
};

export default BlogPage;
